<template>
  <div>
    <b-modal  id="termsAndCondition" title="الشروط والأحكام" size="lg" centered hide-footer>
      <p class="text-black font-size-18 text-justify p-4">{{info.selectedCompany.termsAndConditions}}</p>
    </b-modal>
    <user-info-and-insurance-company />
        <div class="fees-company-container">
          <div>
            <h3 class="fees-company-main-title text-gray">{{ $t('insurance.insureCompanyInfo') }}</h3>
            <b-row>
              <b-col lg="12">
                <div class="fees-company-item">
                  <div class="d-flex justify-content-between flex-md-row flex-column">
                    <div class="fees-company-item-img flex-1">
                        <div class="mb-4">
                          <h5 class="fees-company-item-title">{{ $t('insurance.insuranceCompany') }}</h5>
                          <h5 class="fees-company-item-response">{{ info.selectedCompany.name }}</h5>
                        </div>
                        <img :src="info.selectedCompany.image" alt="" class="img-fluid" />
                      </div>
                    <div class="flex-1">
                      <div class="mb-4">
                        <h5 class="fees-company-item-title">{{$t('insurance.idHolder')}}</h5>
                        <h5 class="fees-company-item-response font-size-16">{{info.national_id}}</h5>
                      </div>
                      <div class="mb-4">
                        <h5 class="fees-company-item-title">{{ $t('insurance.coverage') }}</h5>
                        <h5 class="fees-company-item-response font-size-16">All risk-Institute Cargo Clause A</h5>
                      </div>
                      <div class="mb-4">
                        <h5 class="fees-company-item-title">{{ $t('insurance.enduranceValue') }}</h5>
                        <h5 class="fees-company-item-response font-size-16"> {{info.selectedCompany.options.mount}}% {{
                            $t('insurance.insuredLoss')
                          }}</h5>
                      </div>
                      <div class="mb-4">
                        <h5 class="fees-company-item-title">{{ $t('insurance.amountInsured') }}</h5>
                        <h5 class="fees-company-item-response font-size-16">{{info.totalFeesPackages}} {{$t('insurance.rs')}} </h5>
                      </div>
                    </div>
                    <div class="flex-1">
                      <b-card class="bg-card-gray">
                        <h4 class="text-primary border-header-bottom py-2 font-weight-bold font-size-18 mb-4">{{
                          $t('insurance.pricingDetails')
                          }}</h4>
                        <div class="d-flex justify-content-between align-items-center mb-2">
                          <p class="text-primary font-size-16 m-0">{{ $t('insurance.transportationInsuranceCoverage') }}</p>
                          <h5 class="text-primary font-weight-bold font-size-18">{{ info.selectedCompany.transportaion_coverage }} {{$t('insurance.rs')}} </h5>
                        </div>
                        <div class="d-flex justify-content-between align-items-center mb-2">
                          <p class="text-primary font-size-16 m-0">{{ $t('insurance.valueTax') }} {{ info.selectedCompany.options.tax }}%</p>
                            <h5 class="text-primary font-weight-bold font-size-18">{{ ((info.selectedCompany.options.tax / 100) * info.selectedCompany.transportaion_coverage).toFixed(2) }} {{$t('insurance.rs')}} </h5>
                        </div>
                        <div class="d-flex justify-content-between align-items-center mb-2">
                          <p class="text-primary font-size-16 m-0">{{ $t('insurance.CertificationFees') }}</p>
                          <h5 class="text-primary font-weight-bold font-size-18">{{info.selectedCompany.options.certificate_fees}} {{$t('insurance.rs')}} </h5>
                        </div>
                      </b-card>
                      <b-card class="bg-primary-light">
                        <div class="d-flex justify-content-between align-items-center mb-0">
                          <div class="d-flex flex-column">
                          <p class="font-size-16 m-0 text-white font-weight-bold">{{ $t('insurance.total') }}</p>
                          <p class="font-size-14 m-0 text-white">{{ $t('insurance.includesAllExpenses') }}</p>
                          </div>
                          <h5 class="font-weight-bold font-size-18 text-white">
                            {{((info.selectedCompany.options.tax  / 100) * info.selectedCompany.transportaion_coverage) + info.selectedCompany.transportaion_coverage + info.selectedCompany.options.certificate_fees }}
                            <span>{{$t('insurance.rs')}}</span>
                            </h5>
                        </div>
                      </b-card>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="mb-5">
            <b-row>
              <b-col md="4" class="mt-4 white-input">
                <input-form v-model="iban"  :name='`IBAN`' :label="$t('insurance.insuredAccount')" type="text"  :validate=" { required: true, regex: /^['SA']{2}[0-9]{22}$/}"></input-form>
              </b-col>
              <b-col lg="12">
                <div class="d-flex flex-column gap_1">
                  <b-form-checkbox class="custom-checkbox-color-checked" inline v-model="insurance_type" color="warning"
                                   name="color" value="address based" >
                    <span class="text-black font-size-16 mx-4">{{ $t('insurance.agreeAllInsuranceData') }}</span>
                  </b-form-checkbox>
                  <b-form-checkbox class="custom-checkbox-color-checked" inline v-model="insurance_type_one" color="warning"
                                   name="color" value="remote location" >
                    <span class="text-black font-size-15 mx-4"> {{ $t('insurance.agreeToAll') }} <span class="text-warning text-decoration-underline" @click="showPopupTerms()">{{ $t('insurance.termsAndConditions') }}</span></span>
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>
          </div>
          <payment-component  @payWithWallet="pay" :four-grid="true"  @payWithMaster="pay"/>
        </div>
        <div class="d-flex justify-content-center mb-5">
          <b-button variant="warning" @click="reverse" class="px-5 py-2 iq-border-radius-5 text-center text-white font-weight-bold"> <span class="mx-2"> </span> <i class="las la-angle-right"></i>
            {{ $t('insurance.previous') }}</b-button>
        </div>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import paymentComponent from '@/components/payment'
import userInfoAndInsuranceCompany from '@/modules/servicePages/insurance/components/userInfoAndInsuranceCompany'
export default {
  props: ['info'],
  data () {
    return {
      insurance_type: false,
      iban: '',
      disabled: false,
      disabledCompany: false,
      selectedPayment: '',
      insurance_type_one: false,
      options: [
        {
          text: 'الدفع عند الإستلام',
          value: 'first'
        },
        {
          text: 'الدفع عند الإستلام',
          value: 'first'
        }
      ],
      insuranceCompanyInfos: [
        {
          company: 'one',
          logo: require('../../../../../assets/images/ibbil/elraghi.png'),
          insuredAmount: '5,000 ريال سعودى',
          certificationFees: '20 ريال سعودى',
          transportationCoverage: '300 ريال سعودى',
          tax: '45 ريال سعودى',
          total: '365 ريال سعودى'
        }
      ]
    }
  },
  components: {
    userInfoAndInsuranceCompany,
    paymentComponent
  },
  methods: {
    showPopupTerms () {
      this.$bvModal.show('termsAndCondition')
    },
    pay (data) {
      if (!this.insurance_type || !this.insurance_type_one) {
        core.showSnackbar('error', 'يجب الموافقة على جميع الشروط')
      } else if (!this.iban) {
        core.showSnackbar('error', 'يجب إدخال ال IBAN')
      } else {
        // localStorage.setItem('insuranceStep', 'final')
        this.$emit('saveInsurance', { iban: this.iban, payment_method_id: data.id, PIN_code: data.PIN_code })
      }
    },
    reverse () {
      this.$emit('reverse')
    }
  }
}
</script>

<style  lang="scss">
.fees-company-container {
  margin-bottom: 52px;
  .fees-company-main-title {
    font-size: 20px !important;
    margin-bottom: 32px !important;
    font-weight: bold !important;
  }
  .fees-company-item {
    box-shadow: 0px 3px 20px #0000000D;
    padding: 45px 50px 20px;
    background-color: #fff;
    border-radius: 20px;
    border: 3px solid transparent;
    cursor: pointer;
    transition: .3s;
    &.selected {
      border-color:#6EBF97;
    }
    .fees-company-item-body {
      padding-bottom: 27px;
      margin-bottom: 16px;
      border-bottom: 1px solid #DEDEDE;
    }
    .fees-company-item-info-box {
      margin-bottom: 22px;
      &.last {
        padding-bottom: 22px;
        border-bottom: 1px solid #DEDEDE;
      }
    }
    .fees-company-item-title {
      color: #B1B1B1;
      font-size: 16px;
      margin-bottom: 4px;
    }
    .fees-company-item-response {
      color: #9B9B9B;
      font-size: 20px;
      font-weight: bold;
    }
    .fees-company-item-img, .fees-company-item-info-total {
      .fees-company-item-title {
        font-size: 20px;
      }
      .fees-company-item-response {
        font-size: 20px;
      }
    }
    .fees-company-item-info-total {
      .fees-company-item-response {
        color: #D39D45;
      }
    }
    .fees-company-item-img {
      img {
        max-width: 150px;
      }
    }
    .fees-company-item-note {
      color: #646464;
      font-size: 16px;
      max-width: 290px;
    }
    .privacy-btn {
      font-size: 16px;
      color: #646464;
      padding-bottom: 3px;
      border-bottom: 1px solid #646464;
      font-weight: bold;
      margin-inline-end: 18px;
    }
    .choose {
      height: 50px;
      border: 1px solid #2B584280;
      border-radius: 10px;
      color: #2B5842;
      font-size: 20px;
      width: 111px;
      background-color: transparent;
    }
  }
}
.bg-card-info {
  background: #2B58420D !important;
}
.flex-1 {
  flex: 1;
}
.bg-card-gray {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  background: #2B58420D !important;
  .card-body {
    padding-bottom: 0 !important;
  }
}
.border-header-bottom {
  border-bottom: 1px solid rgba(148, 147, 147, 0.61);
}
.bg-primary-light {
  background: #2B5842BF !important;
}
.payment-mada-img{
  height: 35px;
  width: 55px;
}
.payment-mada-img img{
  background-size: cover;
  width: 100%;
  height: 100%;
}
.payment-visa-img{
  height: 25px;
  width: 45px;
}
.payment-visa-img img{
  background-size: cover;
  width: 100%;
  height: 100%;
}
.white-input input {
  background: #fff !important;
}
#termsAndCondition .modal-header {
  flex-direction: row-reverse;
}
</style>
